<template>
  <div v-if="store.getters.modalEditarPromoter.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full h-5/6 px-4 py-3 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
      role="dialog" id="modal">
      <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
      <header class="flex justify-center items-center relative">
        <h1 class="font-semibold text-xl text-SC_Azul">
          Editar promoter
        </h1>
        <button @click="close"
          class="inline-flex items-center absolute right-0 justify-self-end w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
          aria-label="close">
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd" fill-rule="evenodd"></path>
          </svg>
        </button>
      </header>
      <!-- Modal body -->


      <div class="mt-4 mb-6 h-5/6 overflow-y-auto bg-SC_Light_Gray p-4 rounded-md">
        <div class="flex flex-col lg:grid lg:grid-cols-2 gap-3">


          <Input type="cpf" placeholder="CPF*" id="cpf" v-model="data.doc" readonly/>
          <Input type="default" placeholder="Nome*" id="nome" v-model="data.nome"
             />

          <Input type="rg" placeholder="RG" id="rg" v-model="data.rg"/>
          <Input type="phone" placeholder="Telefone" id="telefone" v-model="data.telefone" />
          <Input type="tax" placeholder="Comissão*" id="comissao" v-model="data.porcentagem" />

          <Input type="default" placeholder="Email*" id="email" v-model="data.email"
            readonly/>

          <Input type="default" placeholder="Referência" id="referencia" v-model="data.referencia" />



            <div class="col-span-2 my-2 flex flex-col gap-1" v-if="!data.edicaoDisponivel">
            <div class="flex items-center p-2">
              <label class="text-SC_Gray ml-3 cursor-pointer" for="cancel_vouchers">
                <input type="checkbox" id="cancel_vouchers" v-model="data.perm_cancel" class="h-4 w-4 cursor-pointer" />
                Permitir cancelamento de vouchers (válido p/ {{ store.getters.evento.nome }})
              </label>
            </div>

            <div class="flex items-center p-2">
              <label class="text-SC_Gray ml-3 cursor-pointer" for="pag_vendas">
                <input type="checkbox" id="pag_vendas" v-model="data.perm_visualiza_qrcode" class="h-4 w-4 cursor-pointer" />
                Visualizar QRCode
              </label>
            </div>

            <div class="flex items-center p-2">
              <label class="text-SC_Gray ml-3 cursor-pointer" for="perm_emit_offline">
                <input type="checkbox" id="perm_emit_offline" v-model="data.perm_emit_offline" class="h-4 w-4 cursor-pointer" />
                Emitir Offline
              </label>
            </div>

            <div class="flex items-center p-2">
              <label class="text-SC_Gray ml-3 cursor-pointer" for="perm_emit_pix">
                <input type="checkbox" id="perm_emit_pix" v-model="data.perm_emit_pix" class="h-4 w-4 cursor-pointer" />
                Emitir Pix
              </label>
            </div>

          </div>
          
          <Input type="cep" placeholder="CEP" id="cep" v-model="data.cep" :toBlur="() => buscarEndereco()" />

          <Input type="default" placeholder="Logradouro (rua)" id="logradouro" v-model="data.logradouro" />
          <Input type="number" placeholder="Número" id="numero" v-model="data.numero" />

          <Input type="default" placeholder="Complemento" id="complemento" v-model="data.complemento" />

          <Input type="cidade" placeholder="Cidade*" id="cidade" v-model="data.cidade"/>

          <Input type="default" placeholder="Bairro" id="bairro" v-model="data.bairro" />
          <Input type="estado" placeholder="UF*" id="uf" v-model="data.uf"/>

          <div class="flex items-center justify-between col-span-2">
            <Button @click="close" color="red-500" text="Cancelar" outline />
            <Button @click="salvar" color="SC_Green" text="Salvar" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import Input from '../../components/Elements/Input.vue';
import Button from '../../components/Elements/Button.vue';
import { isEmptyPromoter, isEmail, isLink, isEmpty } from '../../services/validate';
import { POST, PUT } from '../../services/api';
import account from '../../services/account';
import { cpf } from 'cpf-cnpj-validator';

export default {
  name: 'ModalPromoter',

  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  components: {
    Input,
    Button
  },

  setup() {
    const promoter = {
      nome: "",
      doc: "",
      rg: "",
      email: "",
      referencia: "",
      telefone: "",
      porcentagem: "",
      instagram: "",
      facebook: "",
      logradouro: "",
      numero: "",
      complemento: "",
      cep: "",
      cidade: "",
      bairro: "",
      uf: "",
      link_promoter: "",
      perm_cancel: true,
      create_link_promoter: true,
      perm_visualiza_qrcode: true,
      perm_emit_offline: true,
      perm_emit_pix: true,
      evento_id: 0,
      usuario_id: 0,
      promoters_usuario_id: 0,
      promoters_id: 0
    };

    const store = useStore();
    const data = ref(promoter);
    const promoterAlreadyCreated = ref(false);
    const id_promoters_usuario = ref(null);
    const alert = inject("alert");

    const close = () => {
      store.commit("modalEditarPromoter", { display: false, promoter: {} });
    };


    const salvar = async () => {

      const accountInfo = account.getInfo();

      const payload = {
        ...data.value,
        evento_id: store.getters.evento.id,
        usuario_id: accountInfo.id,
      };


        const payloadValido = {
          nome: data.value.nome,
          //rg: data.value.rg,
          comissao: data.value.porcentagem,
          cidade: data.value.cidade,
          uf: data.value.uf
        }

        const emailValidate = isEmail(payload.email);
        const cpfValidate = cpf.isValid(payload.doc);
        const validate = isEmpty(payloadValido)
        const eventoUsuarioValidate = payload.evento_id > 0 && payload.usuario_id > 0;

        if (emailValidate && cpfValidate && validate === "" && eventoUsuarioValidate) {

          try {
            let resPromoter;
            store.commit('loading', true);

            delete payload.edicaoDisponivel

            const response = await POST('promoter_usuario/editar_promoter', payload)

            if(response.success)
              showAlert('Sucesso', `Promoter ${payload.nome} editado com sucesso!`, 'success');
            else
              showAlert('Erro', `Falha ao editar o Promoter ${payload.nome}`, 'error');


            store.getters.modalEditarPromoter.display = false;
            store.getters.modalEditarPromoter.callbackOK();

            delete payload.value
            delete payloadValido.value
            delete promoterAlreadyCreated.value

          } catch (error) {
            showAlert("Erro", error, 'danger');
          } finally {
            store.commit('loading', false);
          }

        } else {
          let message = "";
          if (!emailValidate) message += 'Digite um <b>email</b> válido!<br>';
          if (!cpfValidate) message += 'Digite um <b>CPF</b> válido!<br>';
          if (!eventoUsuarioValidate) message += 'Evento ou usuário inválido!<br>';
          if (validate !== '') message += validate
          showAlert('Aviso', message, 'warning');
        }
    };

    const showAlert = (title, message, type) => {
      store.commit('alert', {
        display: true,
        title: title,
        message: message,
        type: type
      });
      setTimeout(() => {
        store.commit('alert', {
          display: false,
        });
      }, 6000);
    };

    watch(
      () => [store.getters.modalEditarPromoter.display],
      () => {
        if (store.getters.modalEditarPromoter.display) {
          const promoter = store.getters.modalEditarPromoter.promoter
          
          data.value = {
            promoters_usuario_id: promoter.id,
            promoters_id: promoter.promoters_id,
            nome: promoter.nome,
            doc: promoter.promoter.documento,
            rg: promoter.rg,
            email: promoter.email,
            referencia: promoter.referencia,
            telefone: promoter.telefone,
            porcentagem: promoter.porcentagem,
            logradouro: promoter.logradouro,
            numero: promoter.numero,
            complemento: promoter.complemento,
            cep: promoter.cep,
            cidade: promoter.cidade,
            bairro: promoter.bairro,
            uf: promoter.uf,
            perm_cancel: promoter.promoter_usuario_perfil.perm_cancel === 1 ? true : false,
            perm_visualiza_qrcode: promoter.promoter_usuario_perfil.perm_visualiza_qrcode === 1 ? true : false,
            perm_emit_offline: promoter.promoter_usuario_perfil.perm_emit_offline === 1 ? true : false,
            perm_emit_pix: promoter.promoter_usuario_perfil.perm_emit_pix === 1 ? true : false,

          }


        } else {
          data.value = {
            promoters_usuario_id: 0,
            promoters_id: 0,
            nome: "",
            doc: "",
            rg: "",
            email: "",
            referencia: "",
            telefone: "",
            porcentagem: "",
            instagram: "",
            facebook: "",
            logradouro: "",
            numero: "",
            complemento: "",
            cep: "",
            cidade: "",
            bairro: "",
            uf: "",
            link_promoter: "",
            perm_cancel: true,
            create_link_promoter: true,
            perm_visualiza_qrcode: true,
            evento_id: 0,
            usuario_id: 0,
          };
        }
      })

    const buscarEndereco = async () => {
      if (data.value.cep) {
        const cep = data.value.cep.replace(/\D/g, '');

        if (cep.length === 8) {
          try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            if (response.ok) {
              const endereco = await response.json();

              if (!endereco.erro) {
                data.value.logradouro = endereco.logradouro;
                data.value.bairro = endereco.bairro;
                data.value.cidade = endereco.localidade;
                data.value.uf = endereco.uf;
              } else {
                alert.open(
                  "Atenção",
                  "Não foi possível localizar o CEP informado!",
                  "warning"
                );
              }
            }

          } catch (error) {
            console.log(error)
          }

        } else {
          alert.open(
            "Atenção",
            "Digite um CEP válido",
            "warning"
          );
        }
      }
    }

    return { store, close, salvar, data, buscarEndereco };
  },
}
</script>

<style scoped>
input[type="checkbox"] {
  appearance: none;
  height: 15px;
  width: 15px !important;
  background: #cecdcd;
  border-radius: 4px;
}

input[type="checkbox"]:checked {
  height: 15px;
  width: 15px !important;
  background: #7c1d55;
  border-radius: 4px;
}
</style>