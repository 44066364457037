<template>
  <div v-if="store.getters.modalVincularPromoter.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col justify-center items-center">

          <span v-if="promoterV.length >= 1"></span>
          <img v-else src="@/assets/images/vincularPromoter.png" class="mt-5" width="150" heigth="150">


          <div class="title1 text-SC_Azul text-center p-5 mt-3">
            <span class="font-normal" style="font-size: 20px;"> Você realmente deseja vincular o(s) promoter(s):

              <div v-if="promoterV.length >= 1">
                <p v-for="(promoter, index) in promoterV" :key="index" class="subtitle2  font-bold text-SC_Azul my-3">
                  {{
                    promoter.promoter }}
                </p>
              </div>

              <div v-else>
                <p class="subtitle2  font-bold text-SC_Azul my-3">
                  {{ promoterV.value.promoter }}
                </p>
              </div>



              ao evento <p class="font-bold"> {{ eventoNome }} </p>
            </span>
          </div>

          <div class="col-span-2 my-2 flex flex-col gap-1 ml-7">
            <div class="flex items-center p-2">
              <label class="text-SC_Gray ml-3 cursor-pointer" for="cancel_vouchers">
                <input type="checkbox" id="cancel_vouchers" class="h-4 w-4 cursor-pointer"
                  v-model="permissions.cancel_vouchers" />
                Permitir cancelamento de vouchers (válido p/ {{ store.getters.evento.nome }})
              </label>
            </div>

            <div class="flex items-center p-2">
              <label class="text-SC_Gray ml-3 cursor-pointer" for="visualizar_qrcode">
                <input type="checkbox" id="visualizar_qrcode" class="h-4 w-4 cursor-pointer"
                  v-model="permissions.visualizar_qrcode" />
                Visualizar QRCode
              </label>
            </div>

            <div class="flex items-center p-2">
              <label class="text-SC_Gray ml-3 cursor-pointer" for="perm_emit_offline">
                <input type="checkbox" id="perm_emit_offline" v-model="permissions.perm_emit_offline" class="h-4 w-4 cursor-pointer" />
                Emitir Offline
              </label>
            </div>

            <div class="flex items-center p-2">
              <label class="text-SC_Gray ml-3 cursor-pointer" for="perm_emit_pix">
                <input type="checkbox" id="perm_emit_pix" v-model="permissions.perm_emit_pix" class="h-4 w-4 cursor-pointer" />
                Emitir Pix
              </label>
            </div>

          </div>
        </div>

      </div>
      <div class="flex flex-row justify-between mt-2">
        <button class="botaoFechar" @click="close">Fechar</button>
        <button class="botaoConfirmar" @click="confirmar">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";

export default {
  setup() {
    const store = useStore();
    const promoterV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoNome = ref('')
    const permissions = ref({
      cancel_vouchers: true,
      visualizar_qrcode: true,
      perm_emit_offline: true,
      perm_emit_pix: true,
    })

    const close = () => {
      store.commit("modalVincularPromoter", { display: false, promoter: {}, callbackOK: () => { } });
    };


    eventoNome.value = eventoInfo.getEvento().nome

    watch(
      () => [store.getters.modalVincularPromoter.display],
      () => {
        if (store.getters.modalVincularPromoter.display) {
          promoterV.value = store.getters.modalVincularPromoter.promoter
          if (!promoterV.value.length)
            promoterV.value = [promoterV.value]
        } else {
          promoterV.value = {}
        }
     })

    const confirmar = async () => {
      store.getters.modalVincularPromoter.callbackOK(permissions.value);
      permissions.value.cancel_vouchers = true;
      permissions.value.visualizar_qrcode = true;
      permissions.value.perm_emit_offline = true
      permissions.value.perm_emit_pix = true
    };


    return { store, close, promoterV, eventoNome, confirmar, permissions };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #408135;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #408135;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #2a5523;
  color: white;
}

input:checked+.slider {
  background-color: #4caf50;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

input[type="checkbox"] {
  appearance: none;
  height: 15px;
  width: 15px;
  background: #cecdcd;

  border-radius: 4px;
}

input[type="checkbox"]:checked {
  height: 15px;
  width: 15px;
  background: #7c1d55;
  border-radius: 4px;
}
</style>