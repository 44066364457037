import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/vincularPromoter.png'


const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "bg-white rounded-lg h-full w-full overflow-auto" }
const _hoisted_4 = { class: "flex flex-col justify-center items-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  src: _imports_0,
  class: "mt-5",
  width: "150",
  heigth: "150"
}
const _hoisted_7 = { class: "title1 text-SC_Azul text-center p-5 mt-3" }
const _hoisted_8 = {
  class: "font-normal",
  style: {"font-size":"20px"}
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "subtitle2 font-bold text-SC_Azul my-3" }
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = { class: "col-span-2 my-2 flex flex-col gap-1 ml-7" }
const _hoisted_14 = { class: "flex items-center p-2" }
const _hoisted_15 = {
  class: "text-SC_Gray ml-3 cursor-pointer",
  for: "cancel_vouchers"
}
const _hoisted_16 = { class: "flex items-center p-2" }
const _hoisted_17 = {
  class: "text-SC_Gray ml-3 cursor-pointer",
  for: "visualizar_qrcode"
}
const _hoisted_18 = { class: "flex items-center p-2" }
const _hoisted_19 = {
  class: "text-SC_Gray ml-3 cursor-pointer",
  for: "perm_emit_offline"
}
const _hoisted_20 = { class: "flex items-center p-2" }
const _hoisted_21 = {
  class: "text-SC_Gray ml-3 cursor-pointer",
  for: "perm_emit_pix"
}
const _hoisted_22 = { class: "flex flex-row justify-between mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.store.getters.modalVincularPromoter.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              ($setup.promoterV.length >= 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                : (_openBlock(), _createElementBlock("img", _hoisted_6)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, [
                  _cache[6] || (_cache[6] = _createTextVNode(" Você realmente deseja vincular o(s) promoter(s): ")),
                  ($setup.promoterV.length >= 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.promoterV, (promoter, index) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: index,
                            class: "subtitle2 font-bold text-SC_Azul my-3"
                          }, _toDisplayString(promoter.promoter), 1))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("p", _hoisted_11, _toDisplayString($setup.promoterV.value.promoter), 1)
                      ])),
                  _cache[7] || (_cache[7] = _createTextVNode(" ao evento ")),
                  _createElementVNode("p", _hoisted_12, _toDisplayString($setup.eventoNome), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("label", _hoisted_15, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      id: "cancel_vouchers",
                      class: "h-4 w-4 cursor-pointer",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.permissions.cancel_vouchers) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, $setup.permissions.cancel_vouchers]
                    ]),
                    _createTextVNode(" Permitir cancelamento de vouchers (válido p/ " + _toDisplayString($setup.store.getters.evento.nome) + ") ", 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      id: "visualizar_qrcode",
                      class: "h-4 w-4 cursor-pointer",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.permissions.visualizar_qrcode) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, $setup.permissions.visualizar_qrcode]
                    ]),
                    _cache[8] || (_cache[8] = _createTextVNode(" Visualizar QRCode "))
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", _hoisted_19, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      id: "perm_emit_offline",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.permissions.perm_emit_offline) = $event)),
                      class: "h-4 w-4 cursor-pointer"
                    }, null, 512), [
                      [_vModelCheckbox, $setup.permissions.perm_emit_offline]
                    ]),
                    _cache[9] || (_cache[9] = _createTextVNode(" Emitir Offline "))
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("label", _hoisted_21, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      id: "perm_emit_pix",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.permissions.perm_emit_pix) = $event)),
                      class: "h-4 w-4 cursor-pointer"
                    }, null, 512), [
                      [_vModelCheckbox, $setup.permissions.perm_emit_pix]
                    ]),
                    _cache[10] || (_cache[10] = _createTextVNode(" Emitir Pix "))
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("button", {
              class: "botaoFechar",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)))
            }, "Fechar"),
            _createElementVNode("button", {
              class: "botaoConfirmar",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($setup.confirmar && $setup.confirmar(...args)))
            }, "Confirmar")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}