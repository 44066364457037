<template>
  <div v-if="store.getters.modalTrocaEvento.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2" class="z-99 w-full h-full overflow-auto"
      role="dialog" style="background-color: #F1F2F6;">
      <fa icon="fa-solid fa-angle-left" size="lg" class="cursor-pointer lg:mx-2 iconBackMobile m-3 absolute"
        @click="close" />


      <div class="h-full w-full flex flex-col items-center lg:mt-12 mt-10 lg:pt-10 py-0">

        <!--<div class="w-1/2 p-4 bg-red-500"></div>-->
        <div class="w-2/3 flex flex-col justify-start gap-7"
          v-if="account.getInfo().type === 'organizador' || account.getInfo().type === 'admin'">
          <div class="flex lg:flex-row flex-col lg:justify-between justify-center items-center gap-4">
            <h1 class="text-SC_Azul font-bold lg:text-left text-center" style="font-size: 21px;">Seja bem-vindo <span
                class="font-normal" style="font-size: 14px;">({{ account.getInfo().email }})</span></h1>
            <div class="flex">
              <button class="cadastrarEvento" @click="openModalConvidarColaborador">Convidar colaboradores</button>
            </div>
          </div>

          <div class="mb-7 lg:text-left text-center">
            <a class="text-SC_Gray font-bold hover:text-gray-800 cursor-pointer" style="font-size: 21px;"
              @click="acessarApp">
              <fa icon="fa-solid fa-mobile-screen-button mr-2" size="lg" style="color: #E95D88;" /> Acessar meu App
              Leitor
            </a>
          </div>

        </div>


        <div class="w-2/3">
          <div class="my-3">
            <span class="title">
              <fa icon="fa-solid fa-angle-left" size="lg" class="cursor-pointer lg:mr-2 iconBack" @click="close" />
              Selecione o evento que deseja vizualizar
            </span>
          </div>

          <div class="lg:pl-10">
            <div class="separador"></div>

            <div class="flex flex-col mt-5">
              <div class="flex lg:flex-row flex-col items-center justify-between">
                <div class="relative mb-4">
                  <input type="default" placeholder="Pesquise" v-model="query" autocomplete="on" class="search"
                    @input="getQuery" />
                  <fa icon="magnifying-glass" class="absolute right-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color:#757575" />
                </div>

                <div class="mb-3 flex justify-start ml-4">
                  <input type="checkbox" value="Sim" id="sim" v-model="finalizadoEvento" />
                  <label class="text-lg text-SC_Gray ml-2" for="sim">Exibir eventos encerrados</label>
                </div>

                <div class="flex lg:ml-36 mb-3 ">
                  <button class="cadastrarEvento" @click="cadastrarEventoButton">Cadastrar novo evento</button>
                </div>
              </div>

              <div v-show="emptyState === true" class="flex lg:flex-row flex-col mt-10 empty-state-container">
                <div>
                  <img src="../../../public/Illustration-unboxing.png" width="250" height="250" />
                </div>
                <div class="empty-state-content lg:ml-0" style="color: #211261 ;">
                  <p class="font-extrabold" style="font-size: 21px;">Oops...</p>
                  <p class="font-bold" style="font-size: 16px;">Nenhum evento encontrado</p>
                  <ul class="lista">
                    <li>Confira erros de digitação</li>
                    <li>Caso o evento já tenha sido realizado, marque "sim"</li>
                  </ul>
                </div>
              </div>

              <div style="height: 550px;" class="flex overflow-y-auto justify-center mt-5 w-full pr-12">
                <div class="grid lg:grid-cols-4 grid-cols-1 h-full w-full lg:gap-10">
                  <div v-for="(evento, index) in copyData" :key="index" class="mx-2 my-4">
                    <div class="hoverCard flex flex-col justify-center items-center p-4" @click="selectEvent(evento)">
                      <div>
                        <img class="img"
                          :src="`https://santocartao-files.s3.amazonaws.com/event-pictures/${evento.id}.jpg`"
                          url="https://meuevento.santocartao.com.br/images/no_image.jpg" />
                      </div>
                      <div class="text-center ">
                        <p class="mt-5 eventoName">{{ truncateText(evento.nome, 35) }}</p>
                        <small class="subtitle"> {{ evento.data }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


        </div>

      </div>
    </div>
  </div>

</template>

<!--
    <div v-for="(evento, index) in copyData" :key="index" class="mx-2 my-4">
      <div
        class="h-40 lg:w-80 w-full flex flex-col content-center border-4 border-primary bg-gray-200 rounded border-SC_Azul">
        <div class="grid grid-cols-2 h-full">
          <div class="col-span-1 flex justify-center items-center">
            <img class="img" :src="`https://santocartao-files.s3.amazonaws.com/event-pictures/${evento.id}.jpg`"
              url="https://meuevento.santocartao.com.br/images/no_image.jpg" />
          </div>
          <div class="col-span-1 flex flex-col justify-between">
            <span class="font-bold text-lg text-black truncate-text">
              {{ truncateText(evento.nome, 35) }}
            </span>
            <span class="semi-bold text-base text-black">
              {{ evento.data }}
            </span>
            <div class="flex justify-center p-2">
              <Button color="secondary" :text="'Selecionar'" @click="selectEvent(evento)" />
            </div>
          </div>
        </div>
      </div>
    </div>
 !-->




<!-- <fa icon="magnifying-glass" size="lg" class="text-SC_Azul z-999 ml-2" />
                  <input class="py-2 focus:outline-white form-input mt-1 pl-6 block w-100" type="default"
                    placeholder="Evento" v-model="query" autocomplete="on" @keyup.enter="getQuery" />
                    
                     <Button @click="getQuery" color="primary" :text="`Filtrar`"></Button>
                    -->





<!-- <fa icon="fa-solid fa-xmark" size="xl" @click="close" />-->


<!--<div class="flex justify-start items-center mx-3">
                <input type="checkbox" value="Sim" id="sim" v-model="finalizadoEvento" />
                <label class="text-lg text-SC_Gray ml-2" for="sim">Sim</label>
              </div>-->

<script>
import { POST } from "@/services/api";
import { inject, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import Button from "../Elements/Button.vue";
//import Input from "../Elements/Input.vue";
import moment from "moment-timezone";
import { useRouter } from "vue-router";

export default {
  components: {
    //Button,
    //Input
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const eventos = ref([]);
    const copyData = ref([]);
    const query = ref("");
    const loader = inject("loading");
    const account = inject("account");
    const filtroVenda = inject("filtros");
    const finalizadoEvento = ref(false);
    const emptyState = ref(false)

    const dataInicial = ref({
      nome: '',
      email: '',
    })

    const dataChange = ref({
      nome: '',
      email: '',
      senha: ''
    })

    const acessarApp = () => {
      store.commit('modalAcessarApp', {
        display: true,
        callbackOK: () => { }
      })
    }

    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substr(0, maxLength) + '...';
      }
      return text;
    }

    const getEventos = async () => {
      emptyState.value = false
      try {

        const info = account.getInfo();

        const response = await POST(`event/filter?search=${query.value}`, {
          user: info,
          eventoFinalizado: finalizadoEvento.value,
        });

        if (response.length === 0) {
          emptyState.value = true
        }

        eventos.value = response.map((elemento) => {
          elemento.year = moment(elemento.data).format("YYYY");
          elemento.data = moment(elemento.data).format("DD/MM/YYYY");

          return elemento;
        });

        copyData.value = response;

      } catch (error) {
        console.log(error)
      }
    };

    watch(
      () => store.getters.modalTrocaEvento.display,
      () => {
        if (store.getters.modalTrocaEvento.display) {
          loader.open()
          getEventos();
          if (account.getInfo()) {
            dataInicial.value.email = account.getInfo().email
            dataInicial.value.nome = 'Nome'
            dataChange.value.senha = ''
          }
          loader.close()
        }
      }
    );

    watch(
      async () => finalizadoEvento.value,
      async () => {
        loader.open()
        await getEventos();
        loader.close()
      }
    );

    const find = (query) => {
      const response = [];

      if (eventos.value && query) {
        eventos.value.forEach((item) => {
          const itemString = Object.values(item).join(' ').toLowerCase();

          if (itemString.includes(query.toLowerCase())) {
            response.push(item);
          }
        });
      }

      return response;
    };

    const close = () => {
      if (localStorage.getItem("event_santo_cartao") !== "null")
        store.commit("modalTrocaEvento", { display: false });
    };

    const selectEvent = (evento) => {
      const info = account.getInfo();
      localStorage.removeItem('event_santo_cartao')
      localStorage.setItem("event_santo_cartao", JSON.stringify(evento));
      filtroVenda.removePortaria();
      filtroVenda.removeVenda();
      filtroVenda.removePromoterProduto();
      const body = {
        id: evento.id,
        nome: evento.nome,
        data: evento.data,
        data_inicio: evento.data_inicio,
        data_fim: evento.data_fim,
        year: evento.year,
      };

      store.commit("setEvento", body);
      store.commit("dadosFinanceiro", {
        valorTotal: 0,
        retiradas: [],
        pagamentos: 0,
        provisionamento: [],
        calendario: [],
      });
      query.value = "";
      close();

      window.location.reload();
      router.push(info.type == "promoter" ? "/dashboard_promoter" : "/dashboard");
    };

    const getQuery = async () => {
      emptyState.value = false
      if (query.value != "") {
        await getEventos();
        const result = find(query.value);
        if (result && result.length > 0) {
          copyData.value = result;
        } else {
          copyData.value = [];
          emptyState.value = true
          await getEventos();
          return;
        }
      } else {
        copyData.value = eventos.value;
      }
    };

    const cadastrarEventoButton = () => {
      window.location.href = 'https://form.jotform.com/Santo_Cartao/Cadastrar_Evento'
    }

    const action = () => {
      query.value = "";
      close();
    };

    const convidarColaborador = () => {

    }

    const openModalConvidarColaborador = () => {
      store.commit('modalConvidarColaborador', {
        display: true,
        eventos: copyData.value,
        callbackOK: () => convidarColaborador()
      })
    }


    return {
      store,
      close,
      action,
      eventos,
      selectEvent,
      finalizadoEvento,
      query,
      copyData,
      getQuery,
      emptyState,
      truncateText,
      cadastrarEventoButton,
      account,
      dataInicial,
      dataChange,
      openModalConvidarColaborador,
      acessarApp
    };
  },
};
</script>

<style lang="scss" scoped>
.lista li {
  list-style-type: disc;
  font-size: 13px;
  line-height: 1.8;
}

.modal {
  text-align: center;
  background-color: white;
  height: 100%;
  width: 100%;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
}

.img {
  border-radius: 5px;
  width: 180px;
  height: 180px;
}

.modalBody {
  height: 100%;
}

input[type="checkbox"] {
  appearance: none;
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
}

input[type="checkbox"]:checked {
  background: #7c1d55;
}

.empty-state-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.empty-state-content {
  margin-top: 20px;
  margin-left: 80px;
  line-height: 1.5;
}

.truncate-text {
  white-space: wrap;
}

.separador {
  width: 100%;
  height: 7px;
  background-color: #911945;
  border-radius: 10px;
}

.search {
  background-color: white;
  height: 35px;
  border-radius: 24px;
  width: 272.22px;
  padding-left: 15px;
  border: 1px solid #D9D9D9;
}

.title {
  font-weight: 600;
  font-size: 21px;
  color: #5E5E5E;
}

.iconBack {
  display: inline-block;
}

.iconBackMobile {
  display: none;
  color: #5E5E5E;
}

.cadastrarEvento {
  background-color: #E95D88;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  height: 35px;
}

.eventoName {
  font-weight: 600;
  font-size: 21px;
  color: #361D54;
}

.subtitle {
  color: #777777;
  font-weight: 400;
  font-size: 14px;
}

.modalInicio {
  transition: transform 0.3s ease-in-out;
}

.hoverCard {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  width: 220px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.hoverCard:hover {
  background-color: white;
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
  border-radius: 10px;
  transform: scale(1.05);
}

@media only screen and (max-width: 800px) {
  .empty-state-content {
    margin-top: 20px;
    line-height: 1.5;
    margin-left: 10px;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    color: #5E5E5E;
  }

  .iconBack {
    display: none;
  }

  .iconBackMobile {
    display: block;
  }


}
</style>
