import { decodeJwt } from '../services/util'
import store from '../../store/index'
import { GET } from "@/services/api";


export default {

    getEmail() {
        const token = localStorage.getItem('token_meu_evento');

        if (token) {
            const info = decodeJwt(token)

            return info.email

        }

    },

    async getInfoPromoter() {
        const token = localStorage.getItem('token_meu_evento');

        if (token) {
            let info = decodeJwt(token)

            let data;

            if (info.type === 'promoter') {
                const evento = store.getters.evento

                console.log(evento.id)

                const response = await GET(`evento_promoter_usuario/get_evento_promoter_usuario_info/${info.id}/${evento.id}`)

                if(response.success) {
                    data = response.data
                }

                info = {...info, ...data}

            }

            return info

        }
    },

    getInfo() {
        const token = localStorage.getItem('token_meu_evento');

        if (token) {
            const info = decodeJwt(token)

            return info

        }
    },

    getName() {
        // asd
    },

    isPromoter() {
        // asd
    },

    isAdministrator() {
        const token = localStorage.getItem('token_meu_evento');

        if (token) {
            const info = decodeJwt(token)

            return info.admin

        }
    },

    isOrganizador() {
        // asd
    },

    cancelPermission() {
        // asd
    },

    qrcodePermission() {
        // asd
    },


}